import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby";

import {SERVICES} from "@constants";
import * as styles from "@sc-global";

function ServicesSection(props){
    const data = useStaticQuery(graphql`
        query {
            images: allFile(filter: {relativeDirectory: {eq: "services"}}) {
            edges {
                node {
                relativePath
                name
                childImageSharp {
                    fixed(width: 100, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }
            }
            }
        }
    `)
    return (
        <ServicesList>
            {SERVICES.map((service,index) => {
                try{
                    const image = data.images.edges.find(e => e.node.relativePath.includes(service.label.toLowerCase()));
                    const imageFixed = image.node.childImageSharp.fixed;
                    return (
                        <ServiceWrapper key={index}>
                            <Fade>
                                <Service>
                                    <ServiceImage>
                                        <Img fixed={imageFixed} alt="Service"/>
                                    </ServiceImage>
                                    <ServiceLabel>
                                        {service.label}
                                    </ServiceLabel>
                                    <ServiceDescription>
                                        {service.description}
                                    </ServiceDescription>
                                </Service>
                            </Fade>
                        </ServiceWrapper>
                    )
                }catch(err){
                    console.warn("Failed to get service image",err);
                }
                return null
                
            })}
        </ServicesList>
    )
}
export default ServicesSection;


const ServicesList = styled.ul`
    ${styles.FlexCenter}
    flex-wrap: wrap;
    list-style: none;
    min-height: 500px;
`
const ServiceWrapper = styled.li`
    flex: 0 0 25%;
    padding: 16px;
    cursor: pointer;
    padding-bottom: 64px;

    @media(max-width: 1200px){
        flex: 0 0 50%;
    }

    @media(max-width: 767px){
        flex: 0 0 100%;
        padding: 0;
        padding-bottom: 24px;
    }

`

const Service = styled.div`
    padding: 32px;
    border-color: rgb(237, 235, 252);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;
    border-radius: 4px;
    transition: all .3s ease;
    background: #fff;

    
    &:hover{
        transform: scale(1.1);   
        box-shadow: rgba(var(--primaryRaw), 0.15) 0px 0px 40px;
        border: 1px solid var(--primary);
    }

    @media(max-width: 767px){
        box-shadow: none;
        
        &:hover{
            box-shadow: none;
            border: none;
            transform: none;
        }
    }
`

const ServiceImage = styled.div`
    ${styles.FlexCenter}
    img{
        width: 100px;
    }
`

const ServiceLabel = styled.div`
    text-align: center;
    padding: 32px 0 24px;
    font-size: 1.6rem;
    font-weight: bolder;
`

const ServiceDescription = styled.div`
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: -.01em;
    color: #607179;

    @media(max-width: 767px){
        text-align: center;
    }
`