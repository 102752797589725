import React from 'react';
import "./fancy-button.scss";
const FancyButton = ({text, children, className="", type = 0, ...props}) => {

    return (
        <button className={`button fancy-button ${className}`} {...props}>
            <div className="fancy-button__horizontal"></div>
            <div className="fancy-button__vertical"></div>
            {
                text || children
            }
        </button>
    );
}

export default FancyButton;