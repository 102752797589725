import {css} from 'styled-components';

//positioning

export function PosFillFn(offset = 0) {
    return `
        position: absolute;
        top: ${offset}px;
        bottom: ${offset}px;
        right: ${offset}px;
        left: ${offset}}px;
        
    `
}

export const PosFill = css`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
`

export function PosBottomFn(offset = 0) {
    return `
        position: absolute;
        bottom: ${offset}px;
        right: 0px;
        left: 0px;
    `
}

export const PosBottom = css`
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
`

export function PosTopFn(offset = 0) {
    return `
        position: absolute;
        top: ${offset}px;
        right: 0px;
        left: 0px;
    `
}

export const PosTop = css`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
`

export function PosLeftFn(offset = 0) {
    return `
        position: absolute;
        left: ${offset}px;
        top: 0px;
        bottom: 0px;
    `
}

export const PosLeft = css`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
`

export function PosRightFn(offset = 0) {
    return `
        position: absolute;
        right: ${offset}px;
        top: 0px;
        bottom: 0px;
    `
}

export const PosRight = css`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
`


//Flexbox


export const FlexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`


