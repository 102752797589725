import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import throttle from "lodash/throttle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from "react-scroll";
import { isMobile } from 'react-device-detect';
import Burger from '@animated-burgers/burger-squeeze';
import Fade from 'react-reveal/Fade';
//import '@animated-burgers/burger-squeeze/dist/styles.css'; 

import {SECTIONS} from '@constants';
import * as helpers from "@helpers";



const Navigation = () => {

    const [fixed, setFixed] = useState(false);
    const [scrollTop, setScrollTop] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false)


    const handleDocumentScroll = () => {

        const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const fixedThreshold = isMobile ? vh - 150 : vh + 150;

        window.pageYOffset > fixedThreshold ? setFixed(true) : setFixed(false);
        window.pageYOffset > 300 ? setScrollTop(true) : setScrollTop(false);
        window.pageYOffset > 10 ? !scrolled && setScrolled(true) : setScrolled(false);
    }
    const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 50);

    useEffect(() => {
        window.addEventListener('scroll', handleDocumentScrollThrottled);
    
        return () =>
          window.removeEventListener('scroll', handleDocumentScrollThrottled);
    }, []);

   

    return (
        <>
            <NavigationWrapper id="navigation" className={`navigation ${fixed ? 'fixed' : ''}`}>
            {
               <ul>
                {
                      SECTIONS.map((section,index) => (
                        <li key={index}>
                            <Link spy smooth hashSpy duration={500} activeClass="active" href={`#${section.anchor}`}to={section.anchor}>{section.label}</Link>
                        </li>
                        ))
                }
               </ul>
            }
            {
                fixed &&
                <>
                    <LogoWrapper>
                       <Logo className='animated slideInDown'/>
                    </LogoWrapper>
                    {
                        isMobile && 
                        <Fade>
                            <CVDownload rel="noopener noreferrer" href="https://drive.google.com/open?id=1mj4OHOZKB_N0lumnZaIMhnkDGf8BhueG" target="_blank">
                                <FontAwesomeIcon className="download-icon" icon={'download'}/>
                                <span className="margin-left-10">Download CV</span>
                            </CVDownload>
                        </Fade>
                    }
                </>
            }
            </NavigationWrapper>
            {
                isMobile &&
                <HamburgerWrapper fixed={fixed} isOpen={menuOpen} scrolled={scrolled}>
                    <Burger isOpen={menuOpen} onClick={() => setMenuOpen(prev => !prev)} />
                </HamburgerWrapper>
            }
            <MobileMenuWrapper isOpen={menuOpen}>
            {
                menuOpen &&
               <Fade delay={300}>
                    <div style={{textAlign: 'center'}}>
                        <Logo/>
                            <MobileMenu>
                            {
                                SECTIONS.map((section,index) => (
                                        <li key={index} className="animated slideInUp" style={{animationDelay: `${0.1 * index}s`}}>
                                            <Link  duration={500} activeClass="active" onClick={() => setMenuOpen(false)} href={`#${section.anchor}`}to={section.anchor}>{section.label}</Link>
                                        </li>
                                ))
                            }
                            </MobileMenu>

                    </div>
               </Fade>
            }
            </MobileMenuWrapper> 
            <ScrollToTop visible={scrollTop}/>
        </>
    )
}

const Logo = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.18 185.4">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" d="M259.18,185.4v0L151.92,0,129.57,38.63,107.22,0,0,185.33l23.91-.05,83.31-144.1,10.44,17.93L44.57,185.38H68.49l12.06-20.87,12.3-21.29h73.42l24.44,42.16h23.82l-.19-.33,42.28.33Zm-56.81-20.81-52.28-90.5L138.17,94.55l16.27,28H104.72L151.92,41,223.3,164.49Z"/>
                </g>
            </g>
        </svg>
    )
}


const ScrollToTop = ({visible}) => (
    <ScrollToTopWrapper visible={visible} onClick={() => helpers.scrollTop()}>
        <FontAwesomeIcon icon="arrow-up"/>
    </ScrollToTopWrapper>
)

const LogoWrapper = styled.div`
    position: absolute;
    left: calc(50% - 25px);
    width: 50px;

    .cls-3,
    .cls-2{
        fill: var(--primary);
    }

    svg:hover{
        cursor: pointer;
        transition: all .5s ease;
        transform: scale(1.05) rotate(360deg);
        

        .cls-3,
        .cls-2{
            fill: #000;
        }

        .cls-1{
            fill: var(--primary);
        }
    }

    &::before{
        content: "";
        border-radius: 0px 0px 50% 50%;
        background: white;
        width: 100px;
        height: 80px;
        position: absolute;
        box-shadow: 0px 4px 0px -2px rgba(0,0,0,0.05);
        bottom: -36px;
        left: calc(50% - 50px);
        z-index: -1;
    }

    @media(max-width: 768px){
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            padding: 4px;
            width: 40px;
        }

        ::before{
            display: none;
        }
    }
`

const HamburgerWrapper = styled.div`
    padding: 8px;
    position: fixed;
    top: 8px;
    right: 16px;
    z-index: 10001;

    box-shadow: ${({fixed, isOpen, scrolled}) => fixed || isOpen || !scrolled ? 'none' : 'rgba(0,0,0,0.15) 0px 0px 40px'};
    background: ${({fixed, scrolled}) => fixed || !scrolled ? 'transparent' :  '#fff'};

    .burger {
        height: 3em;
        width: 3em;
        position: relative;
        font-size: 10px;
        cursor: pointer;
        transition: .2s all;
        -webkit-tap-highlight-color: transparent;
      
      
        .burger-lines:after {
          left: 0;
          top: -1em;
        }
        .burger-lines:before {
          left: 1em;
          top: 1em;
        }
      
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 150%;
          width: 150%;
          top: -25%;
          left: -25%;
        }
        .burger-lines {
          top: 50%;
          margin-top: -0.125em;
          &,
          &:after,
          &:before {
            pointer-events: none;
            display: block;
            content: '';
            width: 100%;
            border-radius: 0.25em;
            background-color: var(--text-color);
            height: 0.25em;
            position: absolute;
            transform: rotate(0);
          }
          &:after {
            left: 0;
            top: -1em;
          }
          &:before {
            left: 1em;
            top: 1em;
          }
        }
    }

    .burger.burger-squeeze {
        .burger-lines {
          &,
          &:after,
          &:before {
            transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s;
          }
          &:after,
          &:before {
            content: "";
            width: 2em;
          }
        }
        
        &.open {
          .burger-lines {
            &,
            &:after,
            &:before {
              transition: .2s background-color, .2s top, .2s left, .2s transform .15s;
            }
            & {
              background-color: transparent;
            }
            &:before,
            &:after {
              left: 0.5em;
              top: 0px;
            }
            &:before {
              transform: rotate(-45deg);
            }
            &:after {
              transform: rotate(45deg);
            }
          }
        }
      }
`


const NavigationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 60px;
    background: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transition: height 0.3s ease;

    li a{
        padding: 20px;
        text-decoration: none;
        color: #fff;
        letter-spacing: 0.05em;
        background-color: transparent;
        font-size: 15px;
        transition: color .3s ease;
    },

    li a:hover{
        color: var(--primary);
    }

    ul{
        width: 100%;
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    li{
        min-width: 120px;
        text-align: center;
    }

    li:nth-of-type(3){
        margin-right: 140px;
    }

    li:nth-of-type(4){
        margin-left: 140px; 
    }

    &.fixed{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: #fff;
        box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.05);
        height: 70px;

        a{
            color: var(--text-color);

            &:hover,
            &.active{
                color: var(--primary);
            }
        }
        
    }

    @media(max-width: 768px){
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: transparent;
        box-shadow: none;
        height: 60px;
        justify-content: flex-start;
        padding: 8px 16px;

        ul{
            display: none;
        }

        &.fixed{
            height: 60px;
        }
    }
`

const ScrollToTopWrapper = styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;
    visibility: ${({visible}) => visible ? 'visible' : 'hidden'};
    opacity: ${({visible}) => visible ? '1' : '0'};
    transition: background-color .3s,opacity .5s,visibility .5s,border-radius .1s cubic-bezier(0.85, 0.08, 0, 0.78);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    color: #fff;
    cursor: pointer;
    z-index: 9998;
    border-radius: 4px;
    background: rgba(var(--primaryRaw), .9);

    svg{
        transition: all .3s ease;
    }

    &:hover{
        //border-radius: 8px;
        box-shadow: 0 1px 8px 3px rgba(0,0,0,0.05);
        background: var(--primary);
        svg{
            animation: float 1.5s ease-in-out infinite;
        }
    }

    @media(max-width: 768px){
        bottom: 20px;
        right: 20px;
        height: 40px;
        width: 40px;
    }

`

const MobileMenuWrapper = styled.div`
    position: fixed;
    top: 0;
    height:  ${({isOpen}) => isOpen ? '100vh' : '80px'};
    width: ${({isOpen}) => isOpen ? '100vw' : '40px'};
    right: 0;
    opacity: ${({isOpen}) => isOpen ? '1' : '0'};
    z-index: ${({isOpen}) => isOpen ? '10000' : '-1'};
    background: #fff;
    transition: width .2s ease-in-out,opacity .1s ease-in-out,height .3s ease-in-out .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo{
        padding-bottom: 24px;
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 16px;

        .cls-3,
        .cls-2{
            fill: var(--primary);
        }
    }
    
`
const MobileMenu = styled.div`
    list-style: none;
    margin-bottom: 15%;
    margin-top: 16px;


    li{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg{
        width: 60px;
        margin-bottom: 24px;
    }

    li a{
        transition: color .15s ease-in-out;
        text-decoration: none;
        color: var(--text-color);
        font-size: 1.8rem;
        line-height: 1.8;
    }
`

const CVDownload = styled.a`
    background: var(--primary);
    padding: 12px 24px;
    color: #fff !important;
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;
    margin-left: 24px;
`

export default Navigation;