import React, {useState, useEffect} from 'react';
import Particles from 'react-particles-js';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typist from "react-typist";


import illustration from "@images/hero.svg";
import { FancyButton } from "@components/Buttons";
import {PARTICLES_PARAMS} from "@constants";
import * as styles from "@sc-global";
import {Navigation} from "@components";
import * as helpers from "@helpers";

function HeroSection(props){

    const [typing, setTyping] = useState(true);

    useEffect(() => {
        setTyping(true);
    }, [typing]);

    return (
       <>
            <ParticlesWrapper>
                <Particles params={PARTICLES_PARAMS}/>
            </ParticlesWrapper>
            <HeroContainer>
                <HeroText>
                    <HeroTitle>Hello there! <br/>I'm <h1>Adrian</h1></HeroTitle>
                    <HeroSubtitle>
                    {
                        typing &&
                        <Typist avgTypingDelay={90} cursor={{hideWhenDone: true, hideWhenDoneDelay: 0}} onTypingDone={() => setTimeout(() => setTyping(false), 5500)}> I design & code beautifully crafted web and mobile experiences</Typist>
                    }
                    </HeroSubtitle>
                    <Roles>
                        {
                            ROLES.map( (role, index) => (
                                <Role key={index}/*  className="animated fadeInLeft" */>
                                    <FontAwesomeIcon icon={'crosshairs'} /* className="animated bounceIn" *//>
                                    <span>{role.label}</span>
                                </Role>
                            ))
                        }
                        <HeroButtons /* className="animated fadeIn" */>
                            <FancyButton className="contact-button" onClick={() => helpers.scrollTo('portofolio')}>
                                <span>My Work</span>
                                <FontAwesomeIcon className="arrow-icon" icon={'arrow-right'}/>
                            </FancyButton>
                            <a rel="noopener noreferrer" href="https://drive.google.com/open?id=1mj4OHOZKB_N0lumnZaIMhnkDGf8BhueG" target="_blank" className="button cv-button">
                                <span> Download CV</span>
                                <FontAwesomeIcon className="arrow-icon" icon={'arrow-right'}/>
                                <FontAwesomeIcon className="download-icon" icon={'download'}/>
                            </a>
                        </HeroButtons>
                    </Roles>
                    <SocialWrapper>
                    <Social>
                        <SocialButton rel="noopener noreferrer" href="mailto: contact@adrianmanduc.com" target="_blank">
                            <FontAwesomeIcon icon={["fas", "envelope"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://www.linkedin.com/in/adrian-gabriel-manduc-38532015b/" target="_blank">
                            <FontAwesomeIcon icon={["fab", "linkedin-in"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer"href="https://github.com/adrianmanduc" target="_blank">
                            <FontAwesomeIcon icon={["fab", "github"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://stackoverflow.com/users/3014458/adrianmanduc" target="_blank">
                            <FontAwesomeIcon icon={["fab", "stack-overflow"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://www.facebook.com/adriangabriel.manduc" target="_blank">
                            <FontAwesomeIcon icon={["fab", "facebook-f"]}/>
                        </SocialButton>
                    </Social>
                </SocialWrapper>
                </HeroText>
                <HeroIllustration>
                    <img src={illustration} alt="Illustration" />
                </HeroIllustration>
            </HeroContainer>
            <ScrollDownWrapper>
                <ScrollDown onClick={() => helpers.scrollTo('about')}>
                    <span>Scroll for details</span>
                    <FontAwesomeIcon icon={'angle-down'} className="animated bounceIn"/>
                </ScrollDown>
            </ScrollDownWrapper>
            <NavigationWrapper>
                <Navigation/>
            </NavigationWrapper>
       </>
    )   
}

export default HeroSection;

const COLORS = [
    {code: 'rgb(222, 73, 90)', class: 'theme-red'},
    {code: 'rgb(28, 117, 188)', class: 'theme-blue'},
    {code: 'rgb(80, 232,94)', class: 'theme-green'},
    {code: 'rgb(245, 208, 18);', class: 'theme-yellow'},
    {code: 'rgb(132, 68, 178);', class: 'theme-purple'},
    {code: 'rgb(236, 137, 71);', class: 'theme-orange'},
];

const ROLES = [{label: "Fullstack Web Developer"}, {label: "UI/UX Designer"}, {label: "Mobile App Developer"}, ];

const ParticlesWrapper = styled.div`
    ${styles.PosFill}
    div{
        height: 100%;
        z-index: 0;
    }

    @media(max-width: 768px){
        //display: none;
    }
`
const HeroContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    //max-height: 375px;
    margin: 0 auto;
    margin-top: min(100px, 10%);
    background: #fff;
    z-index: 1;
    box-shadow: rgba(197, 197, 197, 0.21) 0 0 16px;
    border-radius: 4px;
    position: relative;
    align-items: stretch;
    
    &::before{
        content: "";
        position: absolute;
        top: -30px;
        left: -50px;
        width: 160px;
        height: 180px;
        background-image: radial-gradient(rgba(var(--primaryRaw), 0.4) 18%, transparent 0);
        background-size: 15px 15px;
        z-index: -1;
    }

    &::after{
        content: "";
        position: absolute;
        bottom: -27px;
        right: -77px;
        width: 180px;
        height: 180px;
        background-image: radial-gradient(rgba(var(--primaryRaw), 0.9) 18%, transparent 0);
        background-size: 15px 15px;
        z-index: -1;
    }

    @media(max-width: 768px){
        margin: 0;
        max-height: unset;
        margin-top: 32px;

        &::after{
            right: 0px;
        }
    }
`

const HeroIllustration = styled.div`
    z-index: 1;
    background: #fff;
    padding: 16px;
    display: flex;
    align-items: center;

    img{
        height: 330px;
    }

    @media(max-width: 768px){
        display: none;
    }
`

const HeroText = styled.div`
    padding: 32px;
    background: rgba(255, 255, 255, 0.85);
    z-index: 1;


    @media(max-width: 1440px){
        left: 150px;
    }

    @media(max-width: 768px){
        position: static;
        width: 100%;
        padding: 20px;
    }

`

const HeroTitle = styled.div`
    font-size: 2.4rem;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.6px;
    width: 100%;
    
    br{
        display: none;
    }

    h1{
        font-size: 2.4rem;
        margin: 0;
        padding: 0;
        color: var(--primary);
        display: inline-block;
    }

    @media(max-width: 520px){
        br{
            display: block;
        }
    }

    @media(max-width: 320px){
        font-size: 18px;

        br{
            display: none;
        }

        h1{
            font-size: 18px;
        }
    }
`

const HeroSubtitle = styled.div`
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 300;
    color: #607179;
    height: 25px;

    .Typist .Cursor {
        display: inline-block;
      
        &--blinking {
          opacity: 1;
          animation: blink 1s linear infinite;
          @keyframes blink {
            0% { opacity:1; }
            50% { opacity:0; }
            100% { opacity:1; }
          }
        }
    }
    
    @media(max-width: 520px){
        display: none;
    }
`

const Roles = styled.div`
    margin-top: 20px;
`


const getDelay = () => {
    let styles = ``;
    for(let i = 1; i < 10; ++ i){
        const delay = 0.8 * ( i - 1);
        styles += `
            &:nth-of-type(${i}){
                animation-delay: ${delay}s;
                svg{
                    animation-delay: ${delay + 1.0}s;
                }
            }
        `
    }
    return styles;
}

const Role = styled.div`
    display: flex;
    align-items: center;
    margin: 15px 0;
    max-height: 26px;

    svg{
        margin-right: 10px;
        color: var(--primary);
        font-size: 16px;
        max-height: 16px;
        max-width: 16px;
    }

    span{
        font-size: 22px;
    }

    ${getDelay()}

    @media(max-width: 520px){
        span{
            font-size: 18px;
        }
        svg{
            font-size: 15px;
        }
    }

    @media(max-width: 320px){
        span{
            font-size: 14px;
        }
        svg{
            font-size: 12px;
        }
    }
`

const HeroButtons = styled.div`
    ${styles.FlexCenter}
    justify-content: flex-start;
    animation-delay: 2.7s;
    margin-top: 50px;

    .button{
        width: 250px;
        font-weight: 700;

        svg{
            font-size: 1rem;
            margin-left: 15px;
            max-width: 16px;
            max-height: 16px;
        }
    }

    @media(max-width: 520px){
        flex-direction: column;
        margin-top: 32px;
        .button{
            width: 100%;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .cv-button{
            margin-top: 32px;
            color: var(--text-color);
            padding: 16px;

            &::before {
                width: 48px;
                height: 48px;
            }
        }

        .fancy-button {
            padding: 16px;
        }
    }

    @media(max-width: 320px){
       .button{
           padding: 12px;
           font-size: 12px;
       }

       .cv-button{
            margin-top: 24px;
       }

       .cv-button::before{
           height: 40px;
           width: 40px;
       }
    }
`

const ScrollDownWrapper = styled.div`
    ${styles.FlexCenter}
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;

    @media(max-width: 520px){
        display: none;
    }
`

const ScrollDown = styled.div`
    ${styles.FlexCenter}
    flex-direction: column;
    background-color: #000;
    width: 270px;
    height: 81px;
    border-radius: 113px 113px 0 0;
    padding: 8px 24px;
    padding-top: 25px;
    color: #fff;
    position: relative;
    top: 40px;
    z-index: 2;
    cursor: pointer;

    span{
        margin-bottom: 12px;
    }

    .fa-angle-down{
        animation: float 2.5s ease-in-out infinite;
    }
`

const NavigationWrapper = styled.div`
    ${styles.PosBottom}
    height: 60px;
    background: #000;
`


const SocialWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 24px;

    @media(max-width: 767px){
        flex-direction: column;
    }

    @media(max-width: 320px) {
        padding-top: 0;
    }
`


const SocialButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    background: transparent;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 20px;
    padding: 10px;
    margin: 10px;
    border: 2px solid var(--primary);
    transition: background-color .2s ease-in-out,color .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover{
        border: 0;
        background: var(--primary);
        color: #fff;
    }

    @media(max-width: 767px){
        height: 36px;
        width: 36px;
        font-size: 14px;
    }

    @media(max-width: 320px) {
        margin: 6px;
        height: 30px;
        width: 30px;
        font-size: 12px;
    }
`

const Social = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media(max-width: 767px){
        justify-content: center;
        margin-top: 16px;
    }
`

