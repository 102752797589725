import {
    HeroSection,
    AboutSection,
    SkillsSection,
    ContactSection,
    PortofolioSection,
    ServicesSection,
} from "@sections/home";


/* ABOUT IMAGES */
import visionImg from "@images/services/vision.png";
import brainstormImg from "@images/services/brainstorm.png";
import designImg from "@images/services/design.png";
import developmentImg from "@images/services/development.png";


/* PORTOFOLIO IMAGES */
import studySmartImg from "@images/projects/studysmart/main-ss.png";
import studySmartBgImage from "@images/projects/studysmart/slideshow-thumbnail.jpg";
import laminarImg from "@images/projects/laminardata/ss2.png";
import laminarBgImg from "@images/projects/laminardata/slideshow-thumbnail.jpeg";

export const SECTIONS = [
    { 
        id: "hero", 
        label: "Home", 
        anchor: "hero", 
        component: HeroSection, 
        hideTitle: true, 
        full: true 
    },
    {
        id: "about",
        label: "About",
        anchor: "about",
        component: AboutSection,
        dark: true,
        full: true
    },
    {
        id: "services",
        label: "Services",
        anchor: "services",
        component: ServicesSection,
    },
    {
        id: "skills",
        label: "Skills",
        anchor: "skills",
        component: SkillsSection,

    },
    {
        id: "portofolio",
        label: "Portofolio",
        anchor: "portofolio",
        component: PortofolioSection,
    },
    {
        id: "contact",
        label: "Contact",
        anchor: "contact",
        component: ContactSection,
        subtitle: "Ask me something, tell me about your project or just say hello",
        dark: true,
    },
];

export const SERVICES = [
    {
        label: "Vision",
        description: "Working close with the customer to understand the requirements. Which is the purpose of the product? Who is the target audience?",
        image: visionImg
    },
    {
        label: "Brainstorm",
        description: "Researching tools, methods, industry standards and competition in order to find the best solution to achieve the desired goals.",
        image: brainstormImg
    },
    {
        label: "Design",
        description: "Turning ideas into complex flows and layouts. Creating appealing designs, while also striving for maximizing the user experience.",
        image: designImg
    },
    {
        label: "Development",
        description: "Extensive experience in web and mobile development. Delivering complete software solutions using modern tools and technologies.",
        image: developmentImg
    }
]

export const PARTICLES_PARAMS = {
    particles: {
        number: {
            value: 12,
            density: {
                enable: true,
                value_area: 800,
            },
        },
        color: {
            value: "#e4ebed",
        },
        shape: {
            type: "polygon",
            stroke: {
                width: 0,
                color: "#000000",
            },
            polygon: {
                nb_sides: 4,
            },
            image: {
                src: "img/github.svg",
                width: 100,
                height: 100,
            },
        },
        opacity: {
            value: 0.5,
            random: false,
            anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
            },
        },
        size: {
            value: 44.2,
            random: true,
            anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
            },
        },
        line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.16034120608655228,
            width: 1,
        },
        move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
                enable: true,
                rotateX: 600,
                rotateY: 1200,
            },
        },
    },
    interactivity: {
        detect_on: "canvas",
        events: {
            onhover: {
                enable: true,
                mode: "repulse",
            },
            onclick: {
                enable: false,
                mode: "push",
            },
            resize: true,
        },
        modes: {
            grab: {
                distance: 400,
                line_linked: {
                    opacity: 1,
                },
            },
            bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
            },
            repulse: {
                distance: 200,
                duration: 0.4,
            },
            push: {
                particles_nb: 4,
            },
            remove: {
                particles_nb: 2,
            },
        },
    },
    retina_detect: true,
};

export const SKILL_VALUES = [
    {label: "HTML & CSS", value: 100, valueLabel: "Expert"},
    {label: "JavaScript", value: 90, valueLabel: "Advanced"},
    {label: "React", value: 90, valueLabel: "Advanced"},
    {label: "Angular", value: 50, valueLabel: "Intermediate"},
    {label: "Node.js", value: 80, valueLabel: "Advanced"},
    {label: "Backbone.js", value: 80, valueLabel: "Advanced"},
    {label: "Ionic", value: 70, valueLabel: "Intermediate"},
    {label: "React Native", value: 40, valueLabel: "Beginner"},
]


export const FULLPAGE_OPTIONS = {
    scrollSensitivity: 1,
    touchSensitivity: 1,
    scrollSpeed: 200,
    resetSlides: true,
    hideScrollBars: true,
    enableArrowKeys: true,
    activeSlide: 0,
};


export const PORTOFOLIO_SLIDES = [
    {
        id: "slide-ss",
        title: "Study Smart",
        description: " A private tutoring management system",
        backgroundImage: studySmartBgImage,
        images: [studySmartImg],
        tags: ["Angular.js", "Node.js", "MongoDB", "Ionic"],
        actionButton: {
            label: "Details",
            internal: true,
            path: '/portofolio/studysmart'
        },
        colors: {
            background: '#167D97',
            before: '#8abecb',
            tags: '#0d5567'
        }
    },
    {
        id: "slide-laminar",
        title: "Demo Application for Laminar Data",
        description: "A web application which   highlights the business value of Laminar Data – a cloud-based RESTful API dedicated to managing flight, weather and aeronautical data.",
        backgroundImage: laminarBgImg,
        images: [laminarImg],
        tags: ["Angular.js", "Flask"],
        actionButton: {
            label: "Details",
            internal: true,
            path: '/portofolio/laminar'
        },
        colors: {
            background: '#e2a55c',
            before: '#edd0ac',
            tags: '#ca8430'
        }
    }
]
