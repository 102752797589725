import React from "react";
import Helmet from "react-helmet";
import * as FontAwesome from './iconLibrary';
import cssVars from 'css-vars-ponyfill';
import image from "@images/about.png";
import CookieConsent from 'react-cookie-consent';

cssVars({});
export default class Layout extends React.Component {
    render() {
        const { children } = this.props;
        return (
            <div>
                <Helmet
                    title="Adrian-Gabriel Manduc | Fullstack Developer"
                    meta={[
                        { name: 'title', content: 'Adrian-Gabriel Manduc | Fullstack Developer'},
                        { name: 'author', content: 'Adrian-Gabriel Manduc'},
                        { name: 'description', content: 'Fullstack developer based in Bucharest, Romania. Creating high quality, beautifully crafted web and mobile experiences' },
                        { name: 'keywords', content: 'adrian, manduc, adrian-gabriel, adrian manduc, adi, adi manduc, adrian-gabriel manduc, fullstack, developer, frontend, ux, ui, bucharest, romania, mobile, app, UI/UX, design, programming' },
                        { name: 'og:description', content: 'Fullstack developer based in Bucharest, Romania. Creating high quality, beautifully crafted web and mobile experiences'},
                        { name: 'title', content: 'Adrian-Gabriel Manduc | Fullstack Developer', property: 'og:title'},
                        { name: 'og:image', content: image},
                        { name: 'image', property: 'og:image', content: image}
                    ]}
                >
                    <html lang="en"/>
                </Helmet>
                {children}
{/*                 <CookieConsent
                    location="top"
                    buttonText="Accept"
                    declineButtonText="Decline"
                    debug
                    cookieName="gatsby-gdpr-google-analytics">
                    This web page uses cookies to provide the best user experience and analyze its traffic
                </CookieConsent> */}
            </div>
        );
    }
}
