import { library } from '@fortawesome/fontawesome-svg-core'
import { faCode  } from '@fortawesome/free-solid-svg-icons/faCode';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import {faCrosshairs} from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons/faArrowUp';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faFileDownload} from '@fortawesome/free-solid-svg-icons/faFileDownload';
import {faDownload} from '@fortawesome/free-solid-svg-icons/faDownload';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {faCheckDouble} from '@fortawesome/free-solid-svg-icons/faCheckDouble';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons/faLightbulb';
import {faNetworkWired} from '@fortawesome/free-solid-svg-icons/faNetworkWired';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons/faAngleDown';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons/faAngleRight';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import {faMobileAlt} from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faPalette} from '@fortawesome/free-solid-svg-icons/faPalette';
import {faLocationArrow} from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import {faGithub} from '@fortawesome/free-brands-svg-icons/faGithub';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faStackOverflow} from '@fortawesome/free-brands-svg-icons/faStackOverflow';


library.add(
    faCode,
    faLongArrowAltRight,
    faArrowRight,
    faArrowUp,
    faCrosshairs,
    faFileDownload,
    faDownload,
    faChevronUp,
    faCheckDouble,
    faLightbulb,
    faNetworkWired,
    faEye,
    faChevronLeft,
    faChevronRight,
    faAngleDown,
    faMobileAlt,
    faEnvelope,
    faLocationArrow,
    faLinkedinIn,
    faGithub,
    faFacebookF,
    faStackOverflow,
    faAngleLeft,
    faAngleRight,
    faPalette,
    faSpinner,
    faCheck
)