import {scroller as scroll, animateScroll} from "react-scroll";

export function scrollTop() {
    animateScroll.scrollToTop();
}

export function scrollTo(target, options = defaultScrollOptions){
    scroll.scrollTo(target,options);
}

const defaultScrollOptions = {duration: 500, delay: 0,smooth: 'easeOut'}