import React from 'react';
function PageSection({className, title, children, id}){
    return (
        <section id={id} className={`section ${className}`}>
            {
                title &&  
                <div className="section-title">
                    <h2>{title}</h2>
                    <div className="title-border"></div>
                </div>
            }
            <div className="section-content">
                {children}
            </div>
        </section>
    );
}


PageSection.defaultProps = {
    className: "",
    id: "",
    title: '',
}

export default PageSection;