import React from 'react';
import styled from 'styled-components';
import {randomColor} from 'randomcolor';
import { navigateTo } from "gatsby-link";
import studySmartLogo from '../../images/projects/studysmart/mockf.jpg';
import laminarDataLogo from '../../images/projects/laminardata/mockf.png';
import tstLogo from '../../images/projects/tst/mockf.png';
import covidLogo from '../../images/projects/covid/mockf.jpg';
import seatempLogo from '../../images/projects/str/mockf.jpg';
import sotoniteLogo from '../../images/projects/sotonite/mockf.jpg';
import tbLogo from '../../images/projects/tb/mockf.jpg';
import amLogo from '../../images/projects/adrianmanduc/mockf.jpg';
import tpLogo from '../../images/projects/tudorpirvu/mockf.jpg';
import asultanLogo from '../../images/projects/alexandrusultan/mockf.jpg';
import avmarineLogo from '../../images/projects/avmarine/mockf.jpg';
import { Link } from "gatsby";
import Fade from 'react-reveal/Fade';

function PortofolioSection(props){
    return (
        <Projects>
        {
            PROJECTS.map((project, index) => <ProjectItem key={project.title} project={project} delay={index * 100}/> )
        }
        </Projects>
    )
}

const ProjectItem = ({project, delay}) => {
    const {title, keywords, type, url, size, image, external} = project;
    const LinkComponent = !external ? Link : A;
    const linkProps = !external ? {to: url} : {href: url};
    return (
        <Fade bottom delay={delay}>
            <Project size={size} color={randomColor({ luminosity: 'light', hue: 'blue'})}>
                <LinkComponent {...linkProps}>
                    <HoverContainer>
                        <img src={image} alt="Project Image"/>
                        <Overlay>
                            <h3>{title}</h3>
                            <span>View Project</span>
                        </Overlay>
                    </HoverContainer>
                </LinkComponent>
            </Project>
        </Fade>
    )
}

const A = props => <a rel="noopener noreferrer" target="_blank" {...props}/>;


const Projects = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
`

const Project = styled.li`
    height: 168px;
    width: 300px;
    margin: 20px;
    background-image: ${props => `url(${props.image})`};
    background-size: cover;
    background-position: center center;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;

     > a {
        height: unset;
    }
`

const HoverContainer = styled.div`
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: pointer;
    background: var(--primary);
    background: transparent;
    border-radius: 4px;
    box-shadow: rgba(0,0,0,0.15) 0px 0px 40px;

    img {
        display: block;
        position: relative;
        max-width: none;
        width: calc(100% + 20px);
        transition: opacity .35s,-webkit-transform .35s;
        transition: opacity .35s,transform .35s;
        transition: opacity .35s,transform .35s,-webkit-transform .35s;
        transform: translate3d(-10px,0,0);
        backface-visibility: hidden;
    }

    h3, span {
        opacity: 0;
        visibility: hidden;
    }

    h3 { 
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        position: relative;
        font-size: 22px;
        overflow: hidden;
        padding: .5em 0;
        background-color: transparent;
        margin-bottom: 24px;
    }

    h3::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #fff;
        content: "";
        visibility: visible;
        transition: transform .35s, -webkit-transform .35s;
        transform: translate3d(-100%,0,0);
    }

    span {
        color: #fff;
        filter: alpha(opacity=0);
        transition: opacity .35s,transform .35s,-webkit-transform .35s;
        transform: translate3d(100%,0,0);
        display: inline-block;
        font-weight: 700;
    }


    &:hover{

        background: var(--primary);

        h3, span {
            opacity: 1;
            visibility: visible;
        }

        h3 { 
            transition: opacity 1s cubic-bezier(.075,.82,.165,1);
        }

        h3::after {
            transform: translateZ(0);
        }

        span {
            transform: translateZ(0);
        }

        img {
            opacity: .2;
            filter: blur(2px);
            filter: alpha(opacity=40);
            transform: translateZ(0);
        }
    }
`

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`


const PROJECTS = [
    {
        title: "StudySmart",
        keywords: "AngularJs",
        image: studySmartLogo,
        type: ['React'],
        url: 'portofolio/studysmart',
    },
    {
        title: "Laminar Data",
        keywords: "AngularJs",
        image: laminarDataLogo,
        type: ['Angular'],
        url: 'portofolio/laminar',
    },
    {
        title: "Alexandru Sultan",
        keywords: "Angular 2 Universal",
        image: asultanLogo,
        external: true,
        url: 'https://www.alexandrusultan.com'
    },
    {
        title: "AvMarine",
        keywords: "JQuery",
        image: avmarineLogo,
        url: "https://avmarine.eu",
        type: ['JQuery'],
        external: true
    },
    {
        title: "Sea Temperature",
        keywords: "React Native",
        image: seatempLogo,
        url: "https://play.google.com/store/apps/details?id=com.denisad.seaTemp",
        type: ['React Native'],
        external: true
    },
    {
        title: "Tudor Pirvu",
        keywords: "Angular 2",
        image: tpLogo,
        url: "https://tudorpirvu.netlify.app/",
        type: ['Angular'],
        external: true
    },
    {
        title: "Taxi Bucharest",
        keywords: "React Native",
        image: tbLogo,
        url: "https://play.google.com/store/apps/details?id=com.agm.taxi_bucharest",
        type: ['Ionic'],
        external: true
    },
    {
        title: "Adrian Manduc (Old)",
        keywords: "Gatsby.js v1",
        image: amLogo,
        type: ['React'],
        url: 'https://old.adrianmanduc.com',
        external: true
    },
    {
        title: "Tv Show Tracker",
        keywords: "Angular",
        image: tstLogo,
        url: "portofolio/tst",
        type: ['Angular'],
    },
    {
        title: "Covid Tracker",
        keywords: "React",
        image: covidLogo,
        url: "https://covidromania.netlify.app/",
        type: ['JQuery'],
        external: true
    },
    {
        title: "Sotonite",
        keywords: "JQuery",
        image: sotoniteLogo,
        url: "portofolio/sotonite",
        type: ['JQuery']
    },
]

export default PortofolioSection;