import React, {useState} from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function ContactSection(props){
    return (
        <>
            <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="75" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" className="svg-top">
                <path d="M0 0 L50 100 L100 0 Z" fill="white" stroke="white"></path>
            </svg>
            <div>
                <div className="form-container">
                    <ContactForm/>
                </div>
            </div>
            <footer>
                <SocialWrapper>
                   <EmailPhone>
                        <div>
                            <FontAwesomeIcon icon="envelope"/>
                            <a className="label" href="mailto: contact@adrianmanduc.com">contact@adrianmanduc.com</a>
                        </div>
                        <div>
                            <FontAwesomeIcon icon="location-arrow"/>
                            <span className="label">Bucharest, Romania</span>
                        </div>
                   </EmailPhone>
                    <Social>
                        <SocialButton rel="noopener noreferrer" href="mailto: contact@adrianmanduc.com" target="_blank">
                            <FontAwesomeIcon icon={["fas", "envelope"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://www.linkedin.com/in/adrian-gabriel-manduc-38532015b/" target="_blank">
                            <FontAwesomeIcon icon={["fab", "linkedin-in"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://github.com/adrianmanduc" target="_blank">
                            <FontAwesomeIcon icon={["fab", "github"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://stackoverflow.com/users/3014458/adrianmanduc" target="_blank">
                            <FontAwesomeIcon icon={["fab", "stack-overflow"]}/>
                        </SocialButton>
                        <SocialButton rel="noopener noreferrer" href="https://www.facebook.com/adriangabriel.manduc" target="_blank">
                            <FontAwesomeIcon icon={["fab", "facebook-f"]}/>
                        </SocialButton>
                    </Social>
                </SocialWrapper>
                <div className="created-by">
                    Created by me with <span className="heart">&#10084;</span> <span className="keyboard">a keyboard</span>
                </div>
                <div className="copyright">
                    ©{new Date().getFullYear()} Adrian Manduc - All rights reserved
                </div>
            </footer>
        </>
    );
}

export default ContactSection;


const ContactForm = () => {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        try{
            const data = {name, email, message};
            await Axios.post("https://formspree.io/dev.adrianmanduc@gmail.com", data);
            resetForm();
        }catch(error){
            console.warn("Error while submitting contact form");
        } finally {
            setLoading(false);
        }
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="name-group">
                <FloatingLabelControl
                    id="name"
                    name="name"
                    type="text"
                    label="Your Name"
                    value={name}
                    placeholder="Enter your name..."
                    onChange={e => setName(e.currentTarget.value)}
                    required
                />
            </div>
            <div className="email-group">
                <FloatingLabelControl
                    id="email"
                    name="email"
                    type="email"
                    label="Your Email"
                    placeholder="Enter your email..."
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                    required
                />
            </div>
            <div className="message-group">
                <FloatingLabelControl
                    id="message"
                    name="message"
                    type="text"
                    label="Message"
                    placeholder="Enter your message..."
                    value={message}
                    component="textarea"
                    onChange={e => setMessage(e.currentTarget.value)}
                    required
                />
            </div>
            <div className="submit-container">
                <button className="button submit-button" type="submit">
                    {loading ? <FontAwesomeIcon style={{fontSize: "18px"}} spin icon={"spinner"}/> : <span>Send</span>}
                </button>
            </div>
        </form>
    )
}

const SocialWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 24px;

    @media(max-width: 767px){
        flex-direction: column;
    }
`

const EmailPhone = styled.div`

    color: #e4e4e4;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;

    div{
        padding: 8px;
        display: flex;
        align-items: center;
        transition: all .3s ease;

        &:hover{
            color: var(--primary);
        }
    }

    a{
        text-decoration: none;
        color: #e4e4e4;

        &:hover{
            color: var(--primary);
        }
    }


    .label{
        margin-left: 12px;
    }
`

const SocialButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e4e4e4;
    background: transparent;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 20px;
    padding: 10px;
    margin: 10px;
    border: 2px solid #e4e4e4;
    transition: background-color .2s ease-in-out,color .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover{
        border: 0;
        background: var(--primary);
        color: #fff;
    }
`

const Social = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media(max-width: 767px){
        justify-content: center;
        margin-top:32px;
    }
`

const FlControl = styled.div`
  width: 100%;
  background: #0e1011;
  padding: 15px;
  border-radius: 4px;
`;

const FlControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: ${props => props.component === 'textarea' ? 'auto' : '2.5em'};
  min-height: ${props => props.component === 'textarea' ? '3em' : ''};
  font-size: inherit;
`;

const FloatingLabel = styled.label`
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    color: #9b9b9b;
    bottom: ${props => props.component === 'textarea' ? 'auto' : '0'};
    top: ${props => props.component === 'textarea' ? '20px' : 'auto'};
    transition: all 0.2s ease-in-out;
    transform-origin: left top;
    font-size: 18px;
    cursor: text;
    pointer-events: none;
    width: 66.6%;
    &.focused{
        color: var(--primary);
    }
    text-transform: ${props => props.active ? 'uppercase' : ''};
    transform: ${props =>
        props.active ? 'translate3d(0, -100%, 0) scale(0.70)' : 'none' 
    };

`;

const FloatingControlComponent = styled.input`
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: 18px;
    background-color: transparent;
    padding-bottom: 3px;
    color: #fff;
    position: relative;
    resize: ${props => props.component === 'textarea' ? 'vertical' : ''};
    height: ${props => props.component === 'textarea' ? '120px' : ''};
    margin-top: ${props => props.component === 'textarea' ? '20px' : ''};
    font-weight: ${props => props.component === 'textarea' ? '300' : ''};
    &::placeholder {
        color: #9b9b9b;
        opacity: ${props => (props.active ? 1 : 0)};
        transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    }
`;

const FloatingLabelControl = ({className='', component = 'input', label, ...otherProps}) => {

    const {value, placeholder, id} = otherProps;
    label = label || placeholder

    const [isActive, setActive] = useState(value && value.length > 0);
    const [isFocused, setFocused] = useState(false);

    const onFocus = e => {
        setActive(true)
        setFocused(true);
    }

    const onBlur = e => {
        setActive(e.target.value.length !== 0);
        setFocused(false);
    }
    

    return(
        <FlControl>
            <FlControlContainer component={component} className={`${className} ${isActive ? 'active' : ''}`}>
                <FloatingLabel component={component} htmlFor={id} active={isActive} className={`${isFocused ? 'focused' : ''}`}>
                    {label}
                </FloatingLabel>
                <FloatingControlComponent component={component} as={component} active={isActive} {...otherProps} onBlur={onBlur} onFocus={onFocus} className={`${isFocused ? 'focused' : ''}`}/>
            </FlControlContainer>
        </FlControl>
    )

}

