import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const AboutSection = () => {
    return (
        <>
            <Juxtapose />
            <AboutMobile>
                <Slide left>
                    <AboutMobileSection>
                        <Icon>
                            <FontAwesomeIcon icon="code"/>
                        </Icon>
                        <Title>
                            Developer
                        </Title>
                        <Description>
                            Always up to date with the latest trends in technology, writing highly-efficient, well-structured and maintainable code
                        </Description>
                    </AboutMobileSection>
               </Slide>
               <Slide right>
                    <AboutMobileSection>
                        <Icon>
                            <FontAwesomeIcon icon="palette"/>
                        </Icon>
                        <Title>
                            Designer
                        </Title>
                        <Description>
                            Transforming requirements into complex flows and layouts, with a passion for designing unique and appealing interfaces
                        </Description>
                    </AboutMobileSection>
               </Slide>
               <Fade> 
                   <div className="about-image"></div>
                </Fade>
            </AboutMobile>
        </>
    );
}

const AboutMobile = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 767px){
        display: flex;
    }
`

const AboutMobileSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    margin-bottom: 32px;
    padding: 0px 16px;
`
const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--primary);
    padding: 16px;
    height: 54px;
    width: 54px;
`

const Title = styled.div`
    font-size: 1.6rem;
    line-height: 1.6;
    color: #e4e4e4;
`

const Description = styled.p`
    margin-top: 16px;
    text-align: center;
    color: #e4e4e4;
`


//566 x 750

const WIDTH = 420;
const HEIGHT = 452;
const INITIAL_STATE = {
    designer: {
        opacity: 1
    },
    coder: {
        opacity: 1
    },
    designerImg: {
        width: WIDTH / 2,
        opacity: 1,
        right: `calc(50% - ${WIDTH / 2}px)`,
        zIndex: 15
    },
    coderImg: {
        width: WIDTH / 2,
        opacity: 1,
        left: `calc(50% - ${WIDTH / 2}px)`,
        zIndex: 15
    },
    designerBg: {
        opacity: 0,
        bottom: '-40px'
    },
    coderBg: {
        opacity: 0,
        bottom: '-40px'
    },
    coderActive: false,
    designerActive: false
}

export default AboutSection;


class Juxtapose extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.state = INITIAL_STATE;
    }

    handleMouseMove(event) {
        const x = event.clientX;
        const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const middleOffset = vw / 2 - x;
        const bgPositionDelta = middleOffset / 5;
        const bgOpacityDelta = middleOffset / (vw / 2) * 1.25;

        let positionDelta = 0;
        let widthDelta = 0;
        let opacityDelta = 0;


        const offset = Math.abs(middleOffset);
        if (offset > vw / 4) {
            widthDelta = WIDTH
            opacityDelta = 1;
        } else {
            widthDelta = offset / 2;
            opacityDelta = offset * 0.002;
        }
        positionDelta = 75 * offset / (vw / 4);

        
        if (middleOffset < 0) {
            this.moveCoderImage(widthDelta, opacityDelta, positionDelta);
        } else {
            this.moveDesignerImage(widthDelta, opacityDelta, positionDelta);
        }

        

        //this.setState({ designerBg: { opacity: .6 + bgOpacityDelta, left: (120 - bgPositionDelta) + "px" } })
        //this.setState({ coderBg: { opacity: .6 - bgOpacityDelta, right: (120 + bgPositionDelta) + "px" } })

        const designerBg = middleOffset > 450 ? {opacity: 1, bottom: 0} : {};
        const coderBg = middleOffset < -450 ? {opacity: 1, bottom: 0} : {};


        this.setState({designerBg})
        this.setState({ coderBg })
        this.setState({ designer: { opacity: 1 - bgOpacityDelta }, designerActive: middleOffset < -450 })
        this.setState({ coder: { opacity: 1 + bgOpacityDelta }, coderActive: middleOffset > 450 })
    }

    handleMouseOut(event) {
        this.setState(INITIAL_STATE);
    }

    moveCoderImage(widthDelta, opacityDelta, positionDelta) {
        this.setState({ designerImg: { ...this.state.designerImg, width: WIDTH / 2 + widthDelta + "px", right: "calc(50% - " + (WIDTH / 2 - positionDelta) + "px)", zIndex: "5" } })
        this.setState({ coderImg: { ...this.state.coderImg, opacity: 1 - opacityDelta, left: "calc(50% - " + (WIDTH / 2 + positionDelta) + "px)", zIndex: "4" } })
    }

    moveDesignerImage(widthDelta, opacityDelta, positionDelta) {
        this.setState({ designerImg: { ...this.state.designerImg, opacity: 1 - opacityDelta, right: "calc(50% - " + (WIDTH / 2 + positionDelta) + "px)", zIndex: "4" } })
        this.setState({ coderImg: { ...this.state.coderImg, width: WIDTH / 2 + widthDelta + "px", left: "calc(50% - " + (WIDTH / 2 - positionDelta) + "px)", zIndex: "5" } })
    }

    render() {
        return (
            <div onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseOut} className="juxtapose-wrapper">
                <div className="desktop" >
                    <div style={this.state.designer} className={`designer ${this.state.designerActive ? 'active' : ''}`}>
                        <div id="designer-desc" className="description">
                            <div className="title">
                                <span>D</span>esigner
                            </div>
                            <p>Transforming requirements into complex flows and layouts, with a passion for designing unique and appealing interfaces</p>
                        </div>
                    </div>
                    <div style={this.state.coder} className={`coder ${this.state.coder ? 'active' : ''}`}>
                        <div id="coder-desc" className="description">
                            <div className="title">
                                <span>D</span>eveloper
                            </div>
                            <p>Always up to date with the latest trends in technology, writing highly-efficient, well-structured and maintainable code</p>

                        </div>
                    </div>
                    <div style={this.state.designerBg} className="designer-bg"></div>
                    <div style={this.state.designerImg} className="designer-img"></div>
                    <div style={this.state.coderBg} className="coder-bg"></div>
                    <div style={this.state.coderImg} className="coder-img"></div>
                </div >
            </div >
        )
    }
}