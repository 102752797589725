import React, {useState, useEffect} from 'react';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from 'react-slick';

import DockerLogo from "@images/tech-logos/docker-logo.png";
import CCLogo from "@images/tech-logos/cc-logo.png";
import GatsbyLogo from "@images/tech-logos/gatsby-logo.png";
import GraphQLLogo from "@images/tech-logos/graphql-logo.png";
import WebpackLogo from "@images/tech-logos/webpack-logo.png";
import KubernetesLogo from "@images/tech-logos/kubernetes-logo.png";
import BackboneLogo from "@images/tech-logos/backbone-logo.png";
import JqueryLogo from "@images/tech-logos/jquery-logo.png";
import Angular2Logo from "@images/tech-logos/angular2-logo.png";
import Ionic2Logo from "@images/tech-logos/ionic2-logo.png";
import ReactNativeLogo from "@images/tech-logos/react-native-logo.png";
import NodeLogo from "@images/tech-logos/nodejs-logo.png";
import MongoLogo from "@images/tech-logos/mongodb-logo.png";
import TypeScriptLogo from "@images/tech-logos/typescript-logo.png";
import VueLogo from "@images/tech-logos/vuejs-logo.png";
import HerokuLogo from "@images/tech-logos/heroku-logo.png";
import NPMLogo from "@images/tech-logos/npm-logo.png";
import SassLogo from "@images/tech-logos/sass-logo.png";
import GithubLogo from "@images/tech-logos/github-logo.png";
import RedisLogo from "@images/tech-logos/redis-logo.png";
import ReactLogo from "@images/tech-logos/react-logo.png";
import GruntLogo from "@images/tech-logos/grunt-logo.png";
import GulpLogo from "@images/tech-logos/gulp-logo.png";
import BowerLogo from "@images/tech-logos/bower-logo.png";
import AngularLogo from "@images/tech-logos/angular-logo.png";
import FigmaLogo from "@images/tech-logos/figma-logo.png";
import ScalewayLogo from "@images/tech-logos/scaleway-logo.png";
import AWSLogo from "@images/tech-logos/aws-logo.png";
import ReduxLogo from "@images/tech-logos/redux-logo.png";
import FirebaseLogo from "@images/tech-logos/firebase-logo.png";
import DigitalOceanLogo from "@images/tech-logos/digitalocean-logo.png";
import JiraLogo from "@images/tech-logos/jira-logo.png";
import BitbucketLogo from "@images/tech-logos/bitbucket-logo.png";
import * as styles from "@sc-global";
import {SKILL_VALUES} from "@constants";

function SkillsSection(props){
    return (
        <>
            <SkillsWrapper>
                <SkillsDetailsWrapper>
                   <ParagraphsWrapper>
                        <p>
                            The main area of my expertise is full-stack web development using the <span className="highlight">MERN</span> stack (MongoDB, Express.JS, React and Node.js)
                        </p>
                        <p>
                            Extensive experience using <span className="highlight">Ionic</span> and <span className="highlight">React Native</span> for creating and publishing to store mobile applications.
                        </p>
                        <p> Proficient using the <span className="highlight">Adobe Creative Cloud</span> applications and <span className="highlight">Figma</span> for creating low and high fidelity prototypes.</p>
                        <SkillsChartWrapper/>
                   </ParagraphsWrapper>
                </SkillsDetailsWrapper>
                <SkillsSphereWrapper>
                    <SkillsSphere/>
                </SkillsSphereWrapper>
            </SkillsWrapper>
            <SkillsSlider/>
        </>
    )
}


const SkillsChartWrapper = () => {
    return (
        <React.Fragment>
            <SkillsChart/>
            <Traits/>
        </React.Fragment>
    )
}

const SkillsChart = () => {
    return (
       <SkillBars>
            <Gridlines>
                <Gridline value="0"/> 
                <Gridline value="25"/> 
                <Gridline value="50"/> 
                <Gridline value="75"/> 
                <Gridline value="100"/> 
            </Gridlines>
            <AxisWrapper>
              <Axis>Beginner</Axis>
              <Axis>Intermediate</Axis>
              <Axis>Advanced</Axis>
              <Axis>Expert</Axis>
            </AxisWrapper>
          <Fade cascade>
               {
                   SKILL_VALUES.map(skill => (
                       
                       <SkillBarWrapper key={skill.label}>
                           <SkillLabel width={skill.value}>
                               <span>{skill.label}</span>
                           </SkillLabel>
                           <SkillBar width={skill.value}/>
                       </SkillBarWrapper>
                   ))
               }
          </Fade>
       </SkillBars>
    )
}




const Traits = () => {
    return (
        <TraitsContainer>
            {
                TRAITS.map((trait,index) => (
                        <Trait key={index}>
                             <Flip left>
                                <TraitIconContainer>
                                    <FontAwesomeIcon className="trait-icon" icon={trait.icon}/>
                                </TraitIconContainer>
                                <TraitLabel>
                                    {trait.label}
                                </TraitLabel>
                            </Flip>
                        </Trait>
                ))
            }
        </TraitsContainer>
    )
}

export default SkillsSection;

const SkillsSphere = () => {
    useEffect(() => {
        init();
    }, []);


    const init = () => {
        const canvas = document.getElementById("skills-canvas");

        const texts = [ 
            "React Hooks","React Context","Node.js", "styled-components", "React Native", "GraphQL", "Webpack", "TypeScript", "Material UI", "Formik",
            "react-query", "create-react-app", "Gatsby.js", "Redux", "Apex Charts", "Antd Design",
        ];
        const counts = [1,2,1,5,4,2,1];

        const options = {
            tilt: Math.PI / 9,
            initialVelocityX: 0.09,
            initialVelocityY: 0.09,
            initialRotationX: Math.PI * 0.14,
            initialRotationZ: 0,
            width: isMobile ? 300 : 500,
            height: isMobile ? 300 : 500
        };

        wordSphere(canvas, texts, counts, options);

        /**
         * WordSphere
         * Written by Hyojun Kim in 2017. Licensed in MIT.
         */
        function wordSphere(canvas, texts, counts, options) {
            const π = Math.PI; // happy math!
            const {
                width,
                height,
                radius = 150,
                padding = 20,
                fontSize = 16,
                tilt = 0,
                initialVelocityX = 0,
                initialVelocityY = 0,
                initialRotationX = 0,
                initialRotationZ = 0,
            } = options;

            let vx = initialVelocityX;
            let vy = initialVelocityY;
            let rx = initialRotationX;
            let rz = initialRotationZ;

            // canvas setup
            let ctx = canvas.getContext("2d");
            ctx.textAlign = "center";

            // Hi-DPI support
            canvas.width = width * 2;
            canvas.height = height * 2;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;
            ctx.scale(2, 2);

            // scrolling
            let clicked = false;
            let lastX;
            let lastY;

            canvas.addEventListener("mousedown", (event) => {
                clicked = true;
                lastX = event.screenX;
                lastY = event.screenY;
            });

            canvas.addEventListener("mousemove", (event) => {
                if (!clicked) return;
                const [dx, dy] = [event.screenX - lastX, event.screenY - lastY];
                [lastX, lastY] = [event.screenX, event.screenY];

                // rotation update
                rz += -dy * 0.01;
                rx += dx * 0.01;

                // velocity update
                vx = dx * 0.1;
                vy = dy * 0.1;

                if (!looping) startLoop();
            });
            canvas.addEventListener("mouseup", (e) => (clicked = false));
            canvas.addEventListener("mouseleave", (e) => (clicked = false));

            function rot(x, y, t) {
                return [
                    x * Math.cos(t) - y * Math.sin(t),
                    x * Math.sin(t) + y * Math.cos(t),
                ];
            }

            function render() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                let ix = 0,
                    iz = 0,
                    i = 1;
                for (const text of texts) {
                    const degZ = (π / (counts.length - 1)) * iz;
                    const degX = ((2 * π) / counts[iz]) * ix;

                    let x = radius * Math.sin(degZ) * Math.cos(degX);
                    let y = radius * Math.sin(degZ) * Math.sin(degX);
                    let z = radius * Math.cos(degZ) + 8 * (ix % 2); /* randomness */

                    // camera transform
                    [y, z] = rot(y, z, tilt);
                    [x, z] = rot(x, z, rz);
                    [x, y] = rot(x, y, rx);

                    // convert to cartesian and then draw.
                    const alpha = 0.5 + 0.4 * (x / radius);
                    const size = fontSize + 2 + 5 * (x / radius);
                    ctx.fillStyle = `rgba(49,72,88,${alpha})`;
                    ctx.font = `${size}px "proxima-nova", sans-serif`;
                    ctx.fillText(text, y + width / 2, -z + height / 2);

                    ix--;
                    if (ix < 0) {
                        iz++;
                        ix = counts[iz] - 1;
                    }
                    i++;
                }
            }

            // renderer
            let looping = false;
            function rendererLoop() {
                if (looping) window.requestAnimationFrame(rendererLoop);
                render();

                // deacceleration - dirty code xD
                if (vx > 0) vx = vx - 0.01;
                if (vy > 0) vy = vy - 0.01;
                if (vx < 0) vx = vx + 0.01;
                if (vy > 0) vy = vy + 0.01;
                if (vx == 0 && vy == 0) stopLoop();

                rz += vy * 0.01;
                rx += vx * 0.01;
            }

            function startLoop() {
                looping = true;
                window.requestAnimationFrame(rendererLoop);
            }

            function stopLoop() {
                looping = false;
            }
            startLoop();
        }
    };

    return <canvas id="skills-canvas"></canvas>
}


const SkillsSlider = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToScroll: 1,
        slidesToShow: 5,
        responsive: [{ breakpoint: 600, settings: { slidesToShow: 1, dots: false } }, { breakpoint: 800, settings: { slidesToShow: 3 } }, { breakpoint: 1000, settings: { slidesToShow: 4 } }, { breakpoint: 1200, settings: { slidesToShow: 5 } }],
        nextArrow: <SampleNextArrow className="arrow next-arrow" />,
        prevArrow: <SamplePrevArrow className="arrow prev-arrow" />
    };


    const images = [
        ReactLogo, ReduxLogo, SassLogo, NodeLogo, Ionic2Logo, ReactNativeLogo, GatsbyLogo, MongoLogo, GraphQLLogo,  BackboneLogo, JqueryLogo,
        JiraLogo, BitbucketLogo, GithubLogo, NPMLogo, DockerLogo, KubernetesLogo, Angular2Logo, TypeScriptLogo, VueLogo, WebpackLogo,
        ScalewayLogo, AWSLogo, HerokuLogo, FirebaseLogo, DigitalOceanLogo,
        RedisLogo, GruntLogo, GulpLogo, BowerLogo, AngularLogo,  
        CCLogo, FigmaLogo,
    ];
    const slides = images.map((image) =>
        <div className="slide" key={image.toString() + Math.random().toString()}>
            <img src={image} alt="Tech Logo"/>
        </div>
    );
    return (
        <div className="slick-wrapper">
            <Slider {...settings}>
                {slides}
            </Slider>
        </div>
    )
};

function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon="angle-right"/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
              <FontAwesomeIcon icon="angle-left"/>
        </div>
    );
}

const TRAITS = [
    {label: "Problem Solver", icon: "check-double"},
    {label: "Creative", icon: "lightbulb" },
    {label: "Multitasker", icon: "network-wired" },
    {label: "Eye for detail", icon: "eye" },
]



const SkillsWrapper = styled.div`
    ${styles.FlexCenter}
    @media(max-width: 767px){
        flex-direction: column;
    }
`

const ParagraphsWrapper = styled.div`
    padding: 32px;
    background: #fff;
    position: relative;
    box-shadow: rgba(197,197,197,0.21) 0 0 16px;
    border-radius: 4px;

    &::before{
        content: "";
        position: absolute;
        width: 80px;
        height: 280px;
        top: -24px;
        left: -30px;
        z-index: -1;
        background-image: radial-gradient(rgba(var(--primaryRaw), 0.4) 18%, transparent 0);
        background-size: 15px 15px;
    }

    &::after{
        content: "";
        position: absolute;
        width: 280px;
        height: 80px;
        bottom: -40px;
        right: -40px;
        z-index: -1;
        background-image: radial-gradient(rgba(var(--primaryRaw), 0.4) 18%, transparent 0);
        background-size: 15px 15px;
    }

    @media(max-width: 767px){
        padding: 16px;

        &::after {
            right: 0px;
        }
    }
`

const SkillsSphereWrapper = styled.div`
    width: 50%;
    position: relative;
    text-align: center;


    canvas{
        position: relative;
    }

    @media(max-width: 767px){
        width: 100%;
        display: none;
    }
`

const SkillsDetailsWrapper = styled.div`
    width: 50%;
    z-index: 1;

    p{
        font-weight: 400;
        line-height: 1.4em;
        font-size: 16px;
        letter-spacing: 0.5px; 
        margin-bottom: 4px;   
    }

    .highlight{
        font-weight: 900;
        background: linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(var(--primaryRaw),0.1) 50%,rgba(var(--primaryRaw),0.3) 100%);
    }

    @media(max-width: 767px){
        width: 100%;

        p{
            //text-align: center;
            font-size: 14px;
        }
    }
`

const SkillBars = styled.ul`
    margin-right: 8px;
    padding: 24px 0 16px;
    list-style: none;
    position: relative;
`

const SkillBarWrapper = styled.li`
    margin-bottom: 12px;
    margin-left: 1px;
`

const SkillLabel = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;

    @media(max-width: 520px){
        font-size: 11px;
        margin-left: 5px;
    }

`

const SkillBar = styled.div`
    height: 12px;
    border-radius: 0px 8px 8px 0;
    position: relative;
    width: ${({width}) =>  `${width}%`};
    background: var(--primary);
`

const AxisWrapper = styled.li`
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 24px;
`

const Axis = styled.div`
    width: 25%;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    left: 13%;
    bottom: 10px;
    color: #616161;

    @media(max-width: 520px){
        display: none;
    }
`

const Gridlines = styled.div`
`

const Gridline = styled.div`
    position: absolute;
    top: 40px;
    bottom: 0;
    border-left: 1px dashed #d2d2d2;
    left: ${({value}) => `${value}%;`}
    
    &:first-of-type {
        border-left: 1px solid #d2d2d2;
    }
`


const TraitsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 40px;
`

const Trait = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 767px){
        margin-bottom: 16px;
        width: 50%;
    }
`
const TraitIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    color: var(--primary);
    border-radius: 50%;
    border: 3px solid var(--primary);
    position: relative;
    font-size: 20px;

    ::after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        border: none;
        animation: spin 5s linear infinite;
        animation-play-state: paused;
    }
    :hover{
        color: var(--primary);
        border: 0;
        cursor: pointer;
    }
    :hover::after{
        animation-play-state: running;
        border: 3px dashed var(--primary);
    }

`

const TraitLabel = styled.div`
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
`

